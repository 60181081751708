import * as anchor from '@project-serum/anchor';

import { establishConnection, getTokenAccountForMintAddress, fetchAccount, fetchAccountOffer, getTokenAccountForTokenAndOwner, findAssociatedTokenAddress} from "@/libs/solanaConnection";

import { MintLayout, TOKEN_PROGRAM_ID, Token } from '@solana/spl-token';

const SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID = new anchor.web3.PublicKey('ATokenGPvbdGVxr1b2hvZbsiqW5xWH25efTNsLJA8knL');

const TOKEN_METADATA_PROGRAM_ID = new anchor.web3.PublicKey('metaqbxxUerdq28cj1RbAWkYQm3ybzjb6a8bt518x1s');

const CANDY_MACHINE_PROGRAM = new anchor.web3.PublicKey('cndy3Z4yapfJBmL3ShUp5exZKqR3z33thTzeNMm2gRZ');

import { SystemProgram, SYSVAR_SLOT_HASHES_PUBKEY } from '@solana/web3.js';

import { signAndSendTransaction, signAndSendMultipleTransactions } from '@/libs/wallet.js';

import axios from 'axios';

// import { sendTransactions } from '@/libs/connection';

export async function mintOneToken(wallet, candyMachineId, number) {
	
	var payer = wallet.publicKey;
	var connection = await establishConnection();
	
	// console.log("OK mintOneToken");
	// console.log("payer", payer.toString());
	
	// var candyMachineId = new anchor.web3.PublicKey('FviCmwyrsCgFkrtSVy24HWWgFrjBwpQc81gMh2a1bPT9');
	candyMachineId = new anchor.web3.PublicKey(candyMachineId);
	
	// const {candyMachine, goLiveDate, itemsAvailable, itemsRemaining, itemsRedeemed} = await getCandyMachineState(wallet, candyMachineId, connection);
	const candyMachineState = await getCandyMachineState(wallet, candyMachineId, connection);
	
	// console.log('candyMachineState', candyMachineState);
	
	var candyMachine = candyMachineState;
	var goLiveDate = candyMachineState.state.goLiveDate;
	var itemsAvailable = candyMachineState.state.itemsAvailable;
	var itemsRemaining = candyMachineState.state.itemsRemaining;
	var itemsRedeemed = candyMachineState.state.itemsRedeemed;
	
	var userPayingAccountAddress;
	
	if(candyMachine.state.tokenMint) {
		
		userPayingAccountAddress = await findAssociatedTokenAddress(payer, candyMachine.state.tokenMint);
	}
	else {
		
		userPayingAccountAddress = payer;
	}
	
	const candyMachineAddress = candyMachine.id;
	
	var transactions = [];
	
	var i = 0;
	
	while(i < number) {
		
		let mint = anchor.web3.Keypair.generate();
		let userTokenAccountAddress = await findAssociatedTokenAddress(payer, mint.publicKey);
		let remainingAccounts = [];
		let signers = [mint];
		let cleanupInstructions = [];
		
		let instructions = [
		
			anchor.web3.SystemProgram.createAccount({
				fromPubkey: payer,
				newAccountPubkey: mint.publicKey,
				space: MintLayout.span,
				lamports:
				await candyMachine.program.provider.connection.getMinimumBalanceForRentExemption(MintLayout.span),
				programId: TOKEN_PROGRAM_ID,
			}),
			
			Token.createInitMintInstruction(
				TOKEN_PROGRAM_ID,
				mint.publicKey,
				0,
				payer,
				payer,
			),
			
			createAssociatedTokenAccountInstruction(
				userTokenAccountAddress,
				payer,
				payer,
				mint.publicKey,
			),
			
			Token.createMintToInstruction(
				TOKEN_PROGRAM_ID,
				mint.publicKey,
				userTokenAccountAddress,
				payer,
				[],
				1,
			),
		];
		
		if (candyMachine.state.tokenMint) {
			remainingAccounts.push({
				pubkey: userPayingAccountAddress,
				isWritable: true,
				isSigner: false,
			});
			remainingAccounts.push({
				pubkey: payer,
				isWritable: false,
				isSigner: true,
			});
		}
		
		let metadataAddress = await getMetadata(mint.publicKey);
		let masterEdition = await getMasterEdition(mint.publicKey);

		let [candyMachineCreator, creatorBump] = await getCandyMachineCreator(candyMachineAddress);

		instructions.push(await candyMachine.program.instruction.mintNft(creatorBump, {
				accounts: {
					candyMachine: candyMachineAddress,
					candyMachineCreator,
					payer: payer,
					wallet: candyMachine.state.treasury,
					mint: mint.publicKey,
					metadata: metadataAddress,
					masterEdition,
					mintAuthority: payer,
					updateAuthority: payer,
					tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
					tokenProgram: TOKEN_PROGRAM_ID,
					systemProgram: SystemProgram.programId,
					rent: anchor.web3.SYSVAR_RENT_PUBKEY,
					clock: anchor.web3.SYSVAR_CLOCK_PUBKEY,
					recentBlockhashes: SYSVAR_SLOT_HASHES_PUBKEY,
					instructionSysvarAccount: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
				},
				remainingAccounts: remainingAccounts.length > 0 ? remainingAccounts : undefined,
			}),
		);
		
		
		let [collectionPDA] = await getCollectionPDA(candyMachineAddress);
		let collectionPDAAccount = await candyMachine.program.provider.connection.getAccountInfo(collectionPDA);
		
		candyMachine.state.retainAuthority = true;
		candyMachine.state.authority = new anchor.web3.PublicKey('89P6geMH3NmCMB1XFbd1DZGmNnggeSGqF3eVtW92DQRN');
		
		// console.log('collectionPDAAccount', collectionPDAAccount);
		// console.log('candyMachine.state.retainAuthority', candyMachine.state.retainAuthority);
		
		if (collectionPDAAccount && candyMachine.state.retainAuthority) {
			try {
				let collectionData = (await candyMachine.program.account.collectionPda.fetch(collectionPDA,));
				
				// console.log('collectionData', collectionData);
				let collectionMint = collectionData.mint;
				let collectionAuthorityRecord = await getCollectionAuthorityRecordPDA(collectionMint, collectionPDA, );
				
				// console.log('collectionMint', collectionMint);
				
				if (collectionMint) {
					
					let collectionMetadata = await getMetadata(collectionMint);
					let collectionMasterEdition = await getMasterEdition(collectionMint);
					
					// console.log('Collection PDA: ', collectionPDA.toBase58());
					// console.log('Authority: ', candyMachine.state.authority.toBase58());
					
					instructions.push(
						await candyMachine.program.instruction.setCollectionDuringMint({
							accounts: {
								candyMachine: candyMachineAddress,
								metadata: metadataAddress,
								payer: payer,
								collectionPda: collectionPDA,
								tokenMetadataProgram: TOKEN_METADATA_PROGRAM_ID,
								instructions: anchor.web3.SYSVAR_INSTRUCTIONS_PUBKEY,
								collectionMint,
								collectionMetadata,
								collectionMasterEdition,
								authority: candyMachine.state.authority,
								collectionAuthorityRecord,
							},
						}),
					);
				}
			} catch (error) {
				console.error(error);
			}
		}
		
		var transaction = new anchor.web3.Transaction();
		
		instructions.forEach(function(instruction) {
			
			transaction.add(instruction);
		})
		
		transaction.feePayer = new anchor.web3.PublicKey(wallet.publicKey);
		transaction.recentBlockhash = (await connection.getRecentBlockhash()).blockhash;
		
		transaction.sign(mint);
		
		transactions.push(transaction);
		
		await axios.get("https://marketplace.bluediamonds.app/cct/seamt/new_mint/"+mint.publicKey.toString());
		
		i++;
	}
	
	var signatures = await signAndSendMultipleTransactions(wallet, connection, transactions);
	
	console.log('signatures', signatures);
	
	return signatures;
}


export const getCandyMachineState = async function(anchorWallet, candyMachineId, connection) {
	
	// console.log('connection', connection);
	
	const provider = new anchor.Provider(connection, anchorWallet, {preflightCommitment: 'processed'});

	const idl = await anchor.Program.fetchIdl(CANDY_MACHINE_PROGRAM, provider);
	
	// console.log('idl', idl);

	const program = new anchor.Program(idl, CANDY_MACHINE_PROGRAM, provider);

	const state = await program.account.candyMachine.fetch(candyMachineId);
	
	const itemsAvailable = state.data.itemsAvailable.toNumber();
	const itemsRedeemed = state.itemsRedeemed.toNumber();
	const itemsRemaining = itemsAvailable - itemsRedeemed;

	return {
		id: candyMachineId,
		program,
		state: {
			itemsAvailable,
			itemsRedeemed,
			itemsRemaining,
			isSoldOut: itemsRemaining === 0,
			isActive: false,
			isPresale: false,
			isWhitelistOnly: false,
			goLiveDate: state.data.goLiveDate,
			treasury: state.wallet,
			tokenMint: state.tokenMint,
			gatekeeper: state.data.gatekeeper,
			endSettings: state.data.endSettings,
			whitelistMintSettings: state.data.whitelistMintSettings,
			hiddenSettings: state.data.hiddenSettings,
			price: state.data.price,
		},
	};
};

const createAssociatedTokenAccountInstruction = (
  associatedTokenAddress: anchor.web3.PublicKey,
  payer: anchor.web3.PublicKey,
  walletAddress: anchor.web3.PublicKey,
  splTokenMintAddress: anchor.web3.PublicKey,
) => {
  const keys = [
    { pubkey: payer, isSigner: true, isWritable: true },
    { pubkey: associatedTokenAddress, isSigner: false, isWritable: true },
    { pubkey: walletAddress, isSigner: false, isWritable: false },
    { pubkey: splTokenMintAddress, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SystemProgram.programId,
      isSigner: false,
      isWritable: false,
    },
    { pubkey: TOKEN_PROGRAM_ID, isSigner: false, isWritable: false },
    {
      pubkey: anchor.web3.SYSVAR_RENT_PUBKEY,
      isSigner: false,
      isWritable: false,
    },
  ];
  return new anchor.web3.TransactionInstruction({
    keys,
    programId: SPL_ASSOCIATED_TOKEN_ACCOUNT_PROGRAM_ID,
    data: Buffer.from([]),
  });
};

const getMasterEdition = async (
  mint: anchor.web3.PublicKey,
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('edition'),
      ],
      TOKEN_METADATA_PROGRAM_ID,
    )
  )[0];
};

const getMetadata = async (
  mint: anchor.web3.PublicKey,
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID,
    )
  )[0];
};

export const getCandyMachineCreator = async (
  candyMachine: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from('candy_machine'), candyMachine.toBuffer()],
    CANDY_MACHINE_PROGRAM,
  );
};

export const getCollectionPDA = async (
  candyMachineAddress: anchor.web3.PublicKey,
): Promise<[anchor.web3.PublicKey, number]> => {
  return await anchor.web3.PublicKey.findProgramAddress(
    [Buffer.from('collection'), candyMachineAddress.toBuffer()],
    CANDY_MACHINE_PROGRAM,
  );
};

export const getCollectionAuthorityRecordPDA = async (
  mint: anchor.web3.PublicKey,
  newAuthority: anchor.web3.PublicKey,
): Promise<anchor.web3.PublicKey> => {
  return (
    await anchor.web3.PublicKey.findProgramAddress(
      [
        Buffer.from('metadata'),
        TOKEN_METADATA_PROGRAM_ID.toBuffer(),
        mint.toBuffer(),
        Buffer.from('collection_authority'),
        newAuthority.toBuffer(),
      ],
      TOKEN_METADATA_PROGRAM_ID,
    )
  )[0];
};