<template>
  <div>
	<span style="display: block;
    color: white;
    text-align: center;
    margin-bottom: 50px; font-size: 1.2vw; font-weight: bold;">A psychoactive substance used in ancient rituals</span>
    <div class="raffle-page-background">
      <div class="raffle-page-card">

        <span class="raffle-page-title">Sea-MT</span>
        <div class="raffle-page-line"></div>
        

        

        <div class="raffle-page-info">

          <div class="raffle-page-nft">
            <img :src="img" />
          </div>

			<div :class="{'raffle-page-details' : true, 'raffle-page-closed' : raffle.time_left == 'closed'}">
            <div class="raffle-page-details-text">
              
              <p>
                <span>
                  Price:
                </span>
                <span>
                  200 $AQUA /  Sea-MT
                </span>
              </p>
              <p class="raffle-page-tickets">
                <span class="raffle-page-gradient-font gradient-font" @click="withdraw_one_ticket()">
                  &#x2212;
                </span>
                <span>
                  {{ how_many_tickets }}
                </span>
                <span class="raffle-page-gradient-font gradient-font" @click="add_one_ticket()">
                  &#x2b;
                </span>
              </p>
            </div>

            <div class="raffle-page-button button">
              <div class="button-left-border"></div>
              <div class="button-right-border"></div>
              <div class="button-content" @click="buy_tickets()">
                <span>Buy</span>
                <span>{{ how_many_tickets }}</span>
                <span>Sea-MT</span>
              </div>
            </div>

          </div>


        </div>

      </div>
    </div>
    
  </div>
</template>

<script>

import {mintOneToken, getCandyMachineState} from "@/libs/candyMachine";
import {getWalletAddress, getSolanaObject} from '@/libs/wallet';
import {raffle_timer_time_left_for_raffle} from '@/libs/raffleTimer';
import {establishConnection} from '@/libs/solanaConnection';
import {cct_alert} from '@/libs/CCT_alert';

import axios from 'axios';

let config_axios = {
	headers: {
		'Content-Type': 'application/json;'
	}
};

export default {
  name: 'Raffle',
  data : function (){

    return {

		id: this.$route.params.id,
		winners : {},
		raffle: {

			raffle_winners: {},
		},
		how_many_tickets: 1,
		claimer_id: false,
		wallet_tickets_count: 0,
		display_modal_claim: false,
		claimer : {
			twitter_name : "",
			discord_name : ""
		},
		img: 'https://img.coraltribe.io/Sea-MT-THREATENED.jpg'

    }

  },
  methods: {
  
	
	
	withdraw_one_ticket: function() {
		
		this.how_many_tickets--;
		
		if(this.how_many_tickets <= 0)
			this.how_many_tickets = 1;
	},
	
	add_one_ticket: function() {
	
		this.how_many_tickets++;
	},
	
	buy_tickets: async function() {
	
		if(this.$root.wallet_address === null) {
			
			// alert('Please connect your wallet first');
			this.$root.$emit('display_connection_modal');
			return;
		}
		
		this.$root.loading_gif = true;
		
		var candy_machine_pubkey = '6xNTP9w8NHK62LqLHXUeyeAsCxHy8epAjjkfa1GCMMGk';
		
		var signatures = await mintOneToken(getSolanaObject(), candy_machine_pubkey, this.how_many_tickets);
		
		var mint_results = await this.confirm_transactions(signatures);
		
		if(mint_results.success > 0 && mint_results.error == 0) {
			
			cct_alert("All "+mint_results.success+" NFT have been minted successfully !");
		}
		else if(mint_results.success > 0 && mint_results.error > 0) {
			
			cct_alert(+mint_results.success+" NFT have been minted successfully, "+mint_results.error+" minting transactions did not confirm in time, please check your wallet");
		}
		else {
		
			cct_alert(mint_results.error+" minting transactions did not confirm in time, please check your wallet");
		}
		
		this.$root.loading_gif = false;
		

	},
	
	confirm_transactions: async function(signatures) {
		
		var result = {
			
			'success': 0,
			'error': 0,
		};
		
		let connection = await establishConnection();
		
		for(var index in signatures) {
		
			let signature = signatures[index];
		
			if(signature === false) {

				result.error++;
			}
			else {

				try {

					var confirmation = await connection.getConfirmedTransaction(signature);
					
					result.success++;
				}
				catch(e) {

					result.error++;
				}
			}
		}
		
		return result;
		
	},
	
	
	

  
	
  },
  mounted: function(){
	
		var component = this;
	
		// get live raffle
		axios.get('https://marketplace.bluediamonds.app/cct/trip/get_water_condition_image').then(function (result) {
		
			console.log(result);
			
			component.img = 'https://img.coraltribe.io/'+result.data.image;
		});

	},
}
</script>

<style scoped>

</style>